// @ts-check
/** @typedef {import("../../utils/types").GptAdElement} GptAdElement  */
/** @typedef {import("../../utils/types").PrebidUnit} PrebidUnit  */

import { log, logTable } from "../../utils/log";
import { debug } from "../../constants";
import { performance } from "../../utils/perf";
import { loadScript } from "../../utils/load";
import { logExceptions } from "../../utils/exceptions";
import { justInTimeQueue, lazyLoadQueue } from "../../queues";
import { Task } from "../../utils/task";
import { getLoadableAds, inLazyRange } from "../../lazyload";
import { getOptions } from "../../options";
import { experiments } from "../../experiments/constants";
import { getGeoDataSync } from "../../utils/geo";
import { allowAllTracking, isGDPRCmp } from "../../consent";

import {
  basePrebidConfig,
  addGDPRToConfig,
  addCoppaToConfig,
  addUSPrivacyToConfig,
  lazyBidOffset,
  lazyBidOffsetLaterBatch,
  auctionTimeoutLaterBatch,
  addManualUSPrivacyToConfig,
  addPAAPIToConfig,
} from "./settings";

import { describeUnit, getUnitCode, getAdForUnitCode } from "./helpers";

import {
  addAppNexusBid, // aka Xandr
  addIndexExchangeBid,
  addCriteoBid,
  addRubiconBid,
  addPubmaticBid,
  addTeadsBid,
  addTripleLiftBid,
  addConcertBid,
} from "./bidders";
import { isDoNotSell } from "../../consent";
import { dispatchHasAdblock } from "../../utils/adblock";
import { GptAd } from "../../models";

// This is used for cachebusting the CDN
// Remember to update any time you build
const PREBID_VERSION = "v9.30.0-2025-02-18";

/**
 * Map of unit ids we can still fetch and assign bids to
 *
 * @type       {Object}
 */
let unitsWereBid = [];
let prebidBatch = 1;

/**
 * Print a table of bids to the console
 * @param  {Object} [obj={}] maps ad ids to bids
 */
function logBids(obj = {}) {
  if (!Object.keys(obj).length) {
    return;
  }

  let table = [];

  for (let unitId in obj) {
    obj[unitId].bids.map((b) => {
      table.push({
        adUnit: unitId,
        creativeId: b.creativeId,
        adId: b.adId,
        bidder: b.bidder,
        timeToRespond: b.timeToRespond,
        cpm: b.cpm,
        size: b.size,
        statusMessage: b.statusMessage,
        mediaType: b.mediaType,
      });
    });
  }

  logTable(table);
}

/**
 * Sets the bids on the unit
 * @param {PrebidUnit} unit
 */
function addBids(unit, prebidBatch) {
  addAppNexusBid(unit); // aka Xandr
  addIndexExchangeBid(unit);
  addCriteoBid(unit);
  addRubiconBid(unit);
  addPubmaticBid(unit);

  if (prebidBatch < 2 && !debug["only-teads"]) {
    log(`Teads skipped on ${unit._elementId}`);
  } else {
    log(`Teads enabled on ${unit._elementId}`);
    addTeadsBid(unit);
  }

  addConcertBid(unit);
  addTripleLiftBid(unit);
}

function setupSpecialBidderSettings() {
  // @see: https://docs.prebid.org/dev-docs/publisher-api-reference/bidderSettings.html
  window.pbjs.bidderSettings = {
    // Separate TripleLift Display and Video bidding inventory
    // @see: https://atlanticmedia.atlassian.net/wiki/spaces/PEG/pages/2805923853/Prebid+and+TripleLift
    triplelift: {
      adserverTargeting: [
        {
          key: "hb_pb_is_video",
          val: function (bidResponse) {
            const isVideo = bidResponse.ad.includes('format_id":8') ? 1 : 0;
            if (debug.triplelift) {
              console.log(`TripleLift bid hb_pb_is_video: ${isVideo}`);
            }
            return isVideo;
          },
        },
      ],
    },
    // applies to all adapters
    standard: {
      // Allow use of cookies and/or local storage.
      // Was defaulted to true in v6, but now defaults to false.
      storageAllowed: allowAllTracking(),
      // Was defaulted to true in v6, but now defaults to false.
      allowAlternateBidderCodes: true,
    },
  };
}

/**
 * Return all the units in Prebid-form
 * @param gptAds {GptAd[]}
 * @return {PrebidUnit[]}
 */
function describeAdUnits(gptAds) {
  // Convert the GPT Elements into a Prebid-friendly format
  // Filter out units that can't be bid on.
  const units = gptAds.map((ad) => describeUnit(ad)).filter((unit) => unit !== undefined);

  // Add the bids!
  units.forEach((unit) => addBids(unit, prebidBatch));

  // Return all the units that have bidders added to them
  return units.filter((unit) => unit.bids.length > 0);
}

function getPrebidConfig() {
  let config = basePrebidConfig();

  // ----- Consent Check
  // If consent is denied correctly, all requests to /prebid will not have cookies
  const { country, region } = getGeoDataSync();
  const isCalifornia = country === "USA" && region === "CA";
  // EU, prebid will check __tcfapi
  if (isGDPRCmp()) {
    config = addGDPRToConfig(config);
    log("🙈 Prebid configured for GDPR");
  }
  // California, prebid will check __uspapi
  else if (isDoNotSell() || isCalifornia) {
    config = addUSPrivacyToConfig(config);
    log("🙈 Prebid configured for US privacy");
  }
  // Otherwise if no tracking, opt out manually using __uspapi
  // TODO: TEST THIS
  else if (!allowAllTracking()) {
    log("🙈 Prebid configured for non-personalized ads");
    config = addManualUSPrivacyToConfig(config);
  }
  // ----- End Consent

  // On lazy-loading batches, use the increased timeout
  if (prebidBatch > 1) {
    log(`Prebid using a higher timeout for batch ${prebidBatch}`);
    config.bidderTimeout = auctionTimeoutLaterBatch;
  }

  log(`Prebid configured with PAAPI`);
  config = addPAAPIToConfig(config);

  log("Prebid config", config);
  return config;
}

function fetchBids() {
  let gptAds = getLoadableAds()
    .filter((ad) => {
      // This has a potential bid
      return unitsWereBid.indexOf(ad.id) === -1;
    })
    .filter((ad) => {
      let lazy = ad.getLazySetting();

      // Not lazy loaded, go get the bids
      if (lazy === 0) {
        return true;
      }

      // Add the offset so we fetch bids before
      // trying to fetch ads
      lazy = lazy + lazyBidOffset;
      if (prebidBatch > 1) {
        lazy = lazy + lazyBidOffsetLaterBatch;
      }

      return inLazyRange(ad.element, lazy);
    });

  // Nothing to bid on
  if (!gptAds.length) {
    return;
  }

  // Add the units to prebid, and keep track for next time
  gptAds.forEach((ad) => unitsWereBid.push(ad.id));
  const units = describeAdUnits(gptAds);
  window.pbjs.addAdUnits(units);

  // Remove ads that don't have a valid corresponding unit
  // We need to do this before we lock them.
  gptAds = gptAds.filter((ad) => {
    return units.filter((unit) => unit._elementId === ad.id).length;
  });

  // log what batch we're in
  gptAds.map((ad) => log(`${ad.data.id} in Prebid bidding batch ${prebidBatch}`));

  // If this is beyond the first batch, change the config to be
  if (prebidBatch > 1) {
    window.pbjs.setConfig(getPrebidConfig());
  }

  // Lock these ads from loading
  const task = new Task("prebid");
  gptAds.forEach((ad) => ad.tasks.push(task));

  // For logging
  const unitCodes = units.map((unit) => unit.code);

  log(`Prebid requesting bids for ${unitCodes.join(", ")} 🛸`, units);
  const startPerfKey = `ads:prebid-lazybid-start:${unitCodes.join(",")}`;
  const endPerfKey = `ads:prebid-lazybid-end:${unitCodes.join(",")}`;
  const measurePerfKey = `ads:prebid-lazybid:${unitCodes.join(",")}`;

  performance.mark(startPerfKey);

  // Send bid timeout information to DFP
  window.pbjs.onEvent("bidTimeout", function (timedOutBids) {
    gptAds.forEach((ad) => {
      const unitCode = getUnitCode(ad);
      // Get this ad slot's timed out bids, if any
      const matchingBids = timedOutBids.filter((bid) => {
        return bid.adUnitCode === unitCode;
      });
      // If this ad slot had timed out bids, send them to DFP.
      // Otherwise, skip to the next ad slot.
      if (matchingBids.length) {
        // Amazon also sets this targeting, so don't override it
        let timedout = ad.getGptSlot().getTargeting("timedout") || [];
        // Push the ad slot's timed out bids to the timedout array
        matchingBids.forEach((bid) => {
          // Prevent duplicates
          if (timedout.indexOf(bid.bidder) === -1) {
            timedout.push(bid.bidder);
          }
        });
        ad.getGptSlot().setTargeting("timedout", timedout);
      }
    });
  });

  window.pbjs.requestBids({
    adUnitCodes: unitCodes,
    bidsBackHandler: function (bids, didTimeOut) {
      justInTimeQueue.push(function () {
        log(
          `Prebid assigning bids for : 👾 `,
          unitCodes,
          didTimeOut ? "Some bids timed out." : "Bids didn't time out.",
          "bids : ",
          bids
        );

        if (debug.ads) {
          logBids(bids);
        }

        window.pbjs.setTargetingForGPTAsync(unitCodes, function (gptSlot) {
          // Prebid has a profoundly stupid API for mapping custom
          // unit codes back to ads.
          //
          // You pass a function that receives a gpt slot, which
          // then must rendering a filtering function that will receive
          // each unit code. That function should return true or false
          // whether the unitcode matches the gptSlot in its closure.
          //
          // You can QA this by checking the targeting on the slots
          // matches the bids that were received for it.
          return function (unitCode) {
            const elementId = gptSlot.getSlotElementId();
            const ad = getAdForUnitCode(unitCode);
            return ad && ad.id === elementId;
          };
        });

        // Unlock the affected ads
        task.done();

        performance.mark(endPerfKey);
        performance.measure(measurePerfKey, startPerfKey, endPerfKey);
      });
    },
  });

  prebidBatch++;
}

export function resetPrebid() {
  unitsWereBid = [];
  prebidBatch = 1;
  if (window.pbjs) {
    window.pbjs.que.push(function () {
      // by not passing any ad units, it removes all
      window.pbjs.removeAdUnit();
    });
  }
}

function shouldPrebid() {
  let { prebid: scriptUrl } = getOptions();

  if (!scriptUrl) {
    return false;
  }

  return true;
}

export default function prebidjs(resolve = () => {}) {
  if (!shouldPrebid()) {
    return resolve();
  }

  let { prebid: scriptUrl } = getOptions();

  scriptUrl = `${scriptUrl}?v=${PREBID_VERSION}`;

  // GDPR makes everything ugly
  if (isGDPRCmp()) {
    scriptUrl = scriptUrl.replace("/prebid.js", "/prebid.cmp.js");
  }

  loadScript(scriptUrl, "Prebid.js", () => {
    log("Could not load Prebid.js");
    dispatchHasAdblock();
    resolve();
  });

  // Create queue
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];

  // Go forth and bid!
  window.pbjs.que.push(
    logExceptions(function () {
      window.pbjs.setConfig(getPrebidConfig());
      setupSpecialBidderSettings();

      // Fetch some initial bids and set it up
      // to grab new bids as we lazy load.
      fetchBids();
      lazyLoadQueue.push(fetchBids);

      resolve();
    })
  );
}
