// @ts-check
import { log, logError } from "./utils/log";
import { arraysEqual } from "./utils/compare";
import { stripIndent } from "./utils/string";
import { dispatch } from "./utils/event";
import { sizes } from "./sizes";

import { creativeSizeLabels, blockPositionTag } from "./constants";
import { GptAd } from "./models";

// Queues
import { gptQueue } from "./queues";

//
// Listens for Ads Rendered
// Notifies optimizely, sets ad-loaded class
// and other properties on the GPT element.
//
gptQueue.push(function () {
  log("Bind slotRenderEnded handler");
  googletag.pubads().addEventListener("slotRenderEnded", function (event) {
    // Get logger
    log("slotRenderEnded", event);

    // @ts-ignore This exists even though it's not in the documentation
    if (event.slot.getOutOfPage()) {
      log("Out Of Page Unit loaded", event);
    }

    let ad = GptAd.getById(event.slot.getSlotElementId());
    if (!ad) {
      return;
    }

    if (event.isEmpty) {
      log(`Ad loaded empty: ${ad.id} 📭`);
      ad.element.classList.add("is-empty");
      dispatch("ad-empty", ad.element);
      return;
    }

    // Remember size and results on the element
    // Do this for ads that load or are blocked by adops
    ad.size = parseSize(event.size);
    ad.advertiserId = event.advertiserId;
    ad.lineItemId = event.lineItemId;
    ad.creativeId = event.creativeId;
    ad.campaignId = event.campaignId;
    ad.hadViewableImpression = false; // Not yet

    // @HACK: Look for a meta tag that tells us adops is blocking.
    const slotEl = document.getElementById(event.slot.getSlotElementId());
    const encodedBlockPositionTag = blockPositionTag.replace(/"/g, "&quot;");
    if (slotEl?.innerHTML.includes(encodedBlockPositionTag)) {
      log(`Ad loaded suppressed ${ad.id} 🙅‍♂️`);

      // These are for styling only
      ad.element.classList.add("is-empty");
      ad.element.classList.add("is-empty--suppressed");

      // Hide the GPT element to avoid creating a large empty spot
      ad.element.style.display = "none";

      dispatch("ad-empty", ad.element);
      return;
    }

    ad.loaded = true;

    if (
      arraysEqual(ad.size, sizes.native) ||
      arraysEqual(ad.size, sizes.custom) ||
      arraysEqual(ad.size, sizes.responsive) ||
      arraysEqual(ad.size, sizes.cineflex) ||
      arraysEqual(ad.size, sizes.house)
    ) {
      ad.setTabIndex(0);
    } else {
      ad.setTabIndex(-1);
    }

    log(`Ad loaded successfully: ${ad.id} as a ${ad.size.join("x")} 🎣`);

    // Strip out old loaded classes
    let classes = ad.element.className.split(" ").filter(function (cls) {
      return cls.indexOf("ad-loaded") !== 0; // Remove old loaded classes
    });

    // Add new loaded classes
    classes.push("ad-loaded");
    let creativeClassLabel = creativeSizeLabels[ad.size.join("x")] || "standard";
    classes.push(`ad-loaded--${creativeClassLabel}`);

    // push .ad-loaded-dom class when ad window is loaded
    const adFrame = ad.element.getElementsByTagName("iframe")[0];
    if (!adFrame || !adFrame.contentWindow) {
      logError(`Ad loaded without iframe: ${ad.id}`);
    } else {
      if (adFrame.contentWindow.document.readyState == "complete") {
        classes.push("ad-loaded-dom");
      } else {
        adFrame.contentWindow.onload = () =>
          ad && ad.element.classList.add("ad-loaded-dom");
      }
    }

    // Set classes onto the ad
    ad.element.className = classes.join(" ");

    dispatch("ad-loaded", ad.element);

    const isInvisible =
      getComputedStyle(ad.element).display === "none" || ad.element.offsetParent === null;
    const isInDOM = !ad.element.parentElement !== null;
    const hiddenPermitted = ad.element.classList.contains("ad-hidden");
    if (isInvisible && isInDOM && !hiddenPermitted) {
      console.error(
        stripIndent(`
        DANGER ☢️! DANGER ☢️!\n
        The ad with the id of ${ad.id} has loaded with a display
        style of none. This is never allowed to happen. If an
        ad loads, it must be visible.
      `)
      );
    }

    // Responsive ads are 1x3
    if (arraysEqual(ad.size, sizes.responsive)) {
      ad.isResponsive = true;
    } else {
      ad.isResponsive = false;
    }
  });
});

//
// Listen for Viewable impressions.
// Trigger custom events and optimizely events.
//
gptQueue.push(function (e) {
  log("Bind impressionViewable handler");
  googletag.pubads().addEventListener("impressionViewable", (e) => {
    let gptAd = GptAd.getById(e.slot.getSlotElementId());
    if (gptAd) {
      gptAd.hadViewableImpression = true;
      dispatch("viewable-impression", gptAd.element);
      log(`Viewable impression for ${gptAd.id} 👀`);
    }
  });
});

/**
 * @param {null | string | number[]} size
 * @returns {number[]}
 */
function parseSize(size) {
  if (typeof size === "string") {
    try {
      const sizeArr = JSON.parse(size);
      if (Array.isArray(sizeArr)) {
        return sizeArr;
      } else {
        logError(`Size is not an array: ${size}`);
        return [];
      }
    } catch (e) {
      logError(`Could not parse size: ${size}`);
      return [];
    }
  }
  return size ? size : [];
}
