// @ts-check
//
// Blocking vendors.
//
// Third party code that needs to load/setup targeting
// before the ad call can start. Receives and returns a promise.
// If the vendor is not enabled, reject the promise.
//

import amazon from "./amazon";
import prebidjs from "./prebid";
import cleanCreatives from "./clean-creatives";
import hattie from "./hattie";
import carbon from "./carbon";
import doubleVerify from "./doubleverify";
import permutive from "./permutive";
import liveintent from "./liveintent";

const vendors = {
  carbon,
  amazon,
  prebid: prebidjs,
  // cleanCreatives,
  hattie,
  doubleVerify,
  permutive,
  liveintent,
};

export default vendors;
